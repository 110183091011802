import dynamic from "next/dynamic";
import PropTypes from "prop-types";

const GlobalFooter = dynamic(
	() => import("@fdn/new_footer"),
	{ ssr: false },
);

export default function Footer({ isMobile, handleScrollUp }) {

	return (

		<GlobalFooter
			is_buttonToTop
			is_mobile={isMobile}
			femaledaily_url={process.env.NEXT_PUBLIC_ROOT_HOME}
			mommiesdaily_url={process.env.NEXT_PUBLIC_MD_DOMAIN}
			girlsbeyond_url={process.env.NEXT_PUBLIC_GB_DOMAIN}
			beautystudio_url={process.env.NEXT_PUBLIC_ECM_DOMAIN}
			handleScrollUp={handleScrollUp}
			footerMenu={[
				{
					title: "About Us",
					target: "_self",
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/about`,
					id: "id_aboutus",
				},
				{
					title: "Terms & Conditions",
					target: "_self",
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/terms-and-conditions`,
					id: "id_tc",
				},
				{
					title: "Awards",
					target: "_self",
					href: `${process.env.NEXT_PUBLIC_AWARD}`,
					id: "id_awards",
				},
				{
					title: "Feedback",
					target: "_self",
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/feedback`,
					id: "id_feedback",
				},
				{
					title: "Privacy Policy",
					target: "_self",
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/privacy-policy`,
					id: "id_privacy_policy",
				},
				// {
				//   title: "Newsletter",
				//   target: "_self",
				//   href: `${Env.MD_DOMAIN}`,
				//   id: "id_newsletter",
				// },
				// {
				//   title: "Contact",
				//   target: "_self",
				//   href: `${Env.MD_DOMAIN}/contact`,
				//   id: "id_contact",
				// },
				{
					title: "Help",
					target: "_self",
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/help`,
					id: "id_help",
				},
				{
					title: "Media Kit",
					target: "_self",
					href: `${process.env.NEXT_PUBLIC_ROOT_HOME}/media-kit`,
					id: "id_media_kit",
				},
			]}
			sosmedUrl={[
				{
					alt: "logo-fb",
					src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-fb.svg",
					url: "https://www.facebook.com/FemaleDailyNetwork/",
				},
				{
					alt: "logo-twitter",
					src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-twitter.svg",
					url: "https://twitter.com/femaledaily",
				},
				{
					alt: "logo-ig",
					src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-ig.svg",
					url: "https://www.instagram.com/femaledailynetwork/",
				},
				{
					alt: "logo-youtube",
					src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-youtube.svg",
					url: "https://www.youtube.com/user/FemaleDailyNetwork",
				},
				{
					alt: "logo-tiktok",
					src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/ic-round-tiktok.svg",
					url: "https://www.tiktok.com/@femaledailynetwork",
				},
			]}
			footerSite={[
				{
					alt: "logo-fd",
					src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-fd.svg",
					url: `${process.env.NEXT_PUBLIC_ROOT_HOME}`,
					height: 28,
				},
				{
					alt: "logo-md",
					src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-md.svg",
					url: `${process.env.NEXT_PUBLIC_MD_DOMAIN}`,
					height: 28,
				},
				{
					alt: "logo-fds",
					src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-fds.svg",
					url: `${process.env.NEXT_PUBLIC_ECM_DOMAIN}`,
					height: 40,
				},
				{
					alt: "logo-gb",
					src: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-gb.svg",
					url: `${process.env.NEXT_PUBLIC_GB_DOMAIN}`,
					height: 36,
				},
			]}
		/>

	);

}

Footer.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	handleScrollUp: PropTypes.func.isRequired,
};
